/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import { useStaticQuery } from "gatsby"
import Layout from "@components/Layouts"
import SEO from "@components/seo"
import Container from "@components/Container"
import Section from "@components/Section"
import MarkdownBody from "@modules/MarkdownBody"
import { rem } from "@src/theme"
import Spacer from "@components/Spacers"
export default function Privacy(props) {
  const data = useStaticQuery(
    graphql`
      query {
        pageData: markdownRemark(
          fileAbsolutePath: { glob: "**/pages/privacy/index.md" }
        ) {
          html
        }
      }
    `
  )
  return (
    <Layout>
      <SEO title="Privacy" pathname={props.location.pathname} />

      <Section
        className="pink-linear"
        sx={{
          bg: "pink",
          color: "darkPurple",
        }}
      >
        <Container
          sx={{
            maxWidth: rem(880),
          }}
        >
          <div sx={{ bg: "white", pb: 8 }}>
            <Spacer.Large />

            <div
              sx={{
                width: ["90%", null, "80%"],
                margin: "0 auto",
              }}
            >
              <MarkdownBody html={data.pageData.html} />
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  )
}
